import api, { http } from '@/services/api';

export const loginStudent = async () => {
  const result = await api.get('/students/login');
  return result.data;
};

export const validateToken = async () => {
  const result = await api.get('/profiles/validate');
  return result.status;
};

export const emailExists = async (email) => {
  const result = await http.get(`/unprotected/email/exists?email=${encodeURIComponent(email)}`);
  return result.data
};
